import React from 'react';
import Layout from 'components/layout';
import SEO from "components/seo";
import { useStaticQuery, graphql } from 'gatsby';

const tos = require("assets/html/termsofservice.html")

const TermsOfService = () => {

    const data = useStaticQuery(graphql`
        query {
            contentfulLayout(title: {eq: "Cinder"}) {
                id
                title
                description {
                    description
                }
                contentful_id,
                menu {
                    name
                    type
                    menuItems {
                        id
                        title
                        url
                    }
                }
            }
        }
    `);
    const menus = data.contentfulLayout.menu;

    return (
        <Layout menus={ menus }>
            <SEO title="Terms of Service" />
            <div class="flex justify-center">
                <div class="w-full p-10 max-w-4xl rounded shadow-lg bg-white" dangerouslySetInnerHTML={{ __html: tos.default }}> 
                                        
                </div>
            </div>
        </Layout>
    );
};

export default TermsOfService;